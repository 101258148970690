
export interface Subscription {
    id: string;
    name: string;
    description: string;
    subscriptionConditions: string;
    unsubscriptionConditions: string;
    otherSubscriptionConditions: string;
    subscriptionFees?: number | null;
    plans: SubscriptionPlan[];
    services: Service[];
    module?: Module | null;
    display: boolean;
    subscriptions: SubscriptionCustomer[];
    createdAt?: string | null;
    deletedAt?: string | null;
    status?: typeof ACTIVE | null;
    paymentType?: typeof STRIPE | null;
    currency: string;
    locale?: string | null
}

export interface SubscriptionPayload extends Omit<Subscription, 'id' | 'plans'> {
    plans: Omit<SubscriptionPlan, 'id'>[];
}

const ACTIVE = 'ACTIVE';
export const STRIPE = 'STRIPE';
const planner = 'Planner';
type Module = typeof planner;

export interface SubscriptionCustomer {
    id: string;
    subscriber: Subscriber;
    createdAt: string;
    status?: typeof pending|typeof active|typeof canceled|typeof error;
    plans: SubscriptionPlan
}

const active = 'active';
const pending = 'pending';
const canceled = 'canceled';
const error = 'error';

export interface Subscriber {
    id: string
    email: string
    locale: string
    firstName: string
    lastName: string
    phone?: string | null
    subscriptions: SubscriberSubscription[];
    customerId: string
}

export interface SubscriberSubscription {
    id: string;
    name: string;
    subscriptionFormulaId: string;
    createdAt: string;
    activatedAt?: string;
    status: typeof pending|typeof active|typeof canceled|typeof error;
    plan: SubscriptionPlan;
    services: Service[];
    currentPeriodEnd?: string
}

export interface Service {
    id: string
}

export interface SubscriptionPlan {
    id: string;
    price?: number | null;
    tacitRenewal?: boolean | null;
    active?: boolean | null;
    numberOfMonths: number
}

export interface ConfigModule {
    testApiKey: boolean,
    liveApiKey: boolean,
    webhook: boolean,
    liveMode: boolean
}

export const live = 'live';
export const test = 'test';
export type Mode = typeof live | typeof test;
export interface ConfigPayment {
    pk_test?: string;
    sk_test?: string;
    pk_live?: string;
    sk_live?: string;
    mode: Mode;
    type: typeof STRIPE
}

export interface BusinessConfig {
    businessName: string;
    businessEmail: string;
    businessPhone: string;
    frontSubscriptionFormulasUri: string;
    frontTakeAppointmentUri: string;
}

export interface CustomerSubscriptionPayload {
    subscriptionFormulaId: string;
    planId: string;
    email: string;
    locale: string;
    firstName: string;
    lastName: string;
    phone?: string | null;
    frontSubscribeBaseUri: string;
}