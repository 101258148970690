import { createContext, FC, useCallback, useContext, useMemo, useState } from "react";
import {Customer} from "./customers-types";
import { CustomersApi } from "./customers-api";
import { customersApi } from "../config/config";
import {Selection} from "../commons/common-types";
import {MakeDownloadFile} from "../utils/misc";
import {DateToIsoShort} from "../utils/date";

interface CustomersContextProps {
    customersList: Customer[];
    fetchCustomersList(search: string): Promise<void>;
    loadMoreCustomers(search: string): Promise<boolean>;
    exportCustomers(selection: Selection, search: string): Promise<void>;
    getCustomer(id: string): Promise<Customer>;
}

const CustomersContext = createContext<CustomersContextProps>({
    customersList: [],
    fetchCustomersList: () => Promise.reject(new Error('Provider not initialized')),
    loadMoreCustomers: () => Promise.reject(new Error('Provider not initialized')),
    exportCustomers: () => Promise.reject(new Error('Provider not initialized')),
    getCustomer: () => Promise.reject(new Error('Provider not initialized'))
})

export const useCustomersStore = () => useContext<CustomersContextProps>(CustomersContext)

interface CustomersStoreProps {
    token: string;
    codeBouton: string
}

export const CustomersStore: FC<CustomersStoreProps> = ({token, codeBouton, children}) => {
    const api = useMemo<CustomersApi>(() => new CustomersApi(customersApi), []);
    const [customersList, setCustomersList] = useState<Customer[]>([]);

    const fetchCustomersList = useCallback(async (search: string) => {
        const response = await api.getCustomersList(codeBouton, token, 10, 0, search);
        setCustomersList(response.result)
    }, [api, codeBouton, token])

    const loadMoreCustomers = async (search: string) => {
        const response = await api.getCustomersList(codeBouton, token, 10, customersList.length, search);
        setCustomersList([...customersList, ...response.result])
        return (customersList.length + response.count) >= response.total
    }

    const exportCustomers = async (selection: Selection, search: string) => {
        const response = await api.exportCustomers(codeBouton, token, selection, search);
        MakeDownloadFile(response, `export_contact_${DateToIsoShort(new Date())}.csv`)
    }

    const getCustomer = useCallback(async (id: string) => {
        return await api.getCustomer(codeBouton, token, id)
    }, [api, codeBouton, token])

    return <CustomersContext.Provider value={{customersList, fetchCustomersList, loadMoreCustomers, exportCustomers, getCustomer}}>
        {children}
    </CustomersContext.Provider>
}