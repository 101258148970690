import {FC, useEffect, useState} from "react";
import {Card, Checkbox, Col, Row, SpanSubtitle1, SquareButton, ThemeType} from "@linkeo.com/ui-lib-react";
import styled, {useTheme} from "styled-components";

const Article = styled(Card)`
  margin-bottom: 32px;

  @media screen and (max-width: 572px) {
    padding: 8px 16px;
  }
`

interface CustomerCardProps {
    firstName: string;
    lastName: string;
    email: string;
    phone?: string | null;
    onEditClick: () => void;
    isSelected: boolean;
    onSelect: () => void;
}

export const CustomerCard: FC<CustomerCardProps> = ({
                                                        firstName,
                                                        lastName,
                                                        email,
                                                        phone,
                                                        onEditClick,
                                                        isSelected,
                                                        onSelect
                                                    }) => {
    const theme = useTheme() as ThemeType;
    const [checked, setChecked] = useState<boolean>(false);

    useEffect(() => {
        setChecked(isSelected)
    }, [isSelected])

    const onCustomerSelect = () => {
        setChecked(!checked);
        onSelect()
    }

    return <Article>
        <Row alignItems={'center'}>
            <Col columns={[0.5]}>
                <Checkbox onChange={onCustomerSelect} value={checked}/>
            </Col>
            <Col columns={[12, 6, 4]}>
                <SpanSubtitle1 style={{color: theme.colors.dark, fontWeight: '700', wordWrap: 'break-word'}}>
                    {firstName} {lastName}
                </SpanSubtitle1>
            </Col>
            <Col columns={[12, 6, 4.5]}>
                <SpanSubtitle1 style={{wordWrap: 'break-word'}}>{email}</SpanSubtitle1>
            </Col>
            <Col columns={[12, 6, 2]}>
                {phone && <SpanSubtitle1 style={{wordWrap: 'break-word'}}>{phone}</SpanSubtitle1>}
            </Col>
            <Col columns={[12, 6, 1]}>
                <SquareButton backgroundColor={theme.colors.secondary}
                              onClick={onEditClick}
                              icon={'edit'}
                              fontColor={theme.colors.light}>
                </SquareButton>
            </Col>
        </Row>
    </Article>
}