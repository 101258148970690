import {createContext, FC, useCallback, useContext, useMemo, useState} from "react";
import {
    BusinessConfig,
    ConfigModule,
    ConfigPayment, CustomerSubscriptionPayload,
    STRIPE,
    Subscriber,
    Subscription,
    SubscriptionPayload,
    test
} from "./payment-types";
import {PaymentApi} from "./payment-api";
import {paymentApi} from "../config/config";
import {Selection} from "../commons/common-types";
import {MakeDownloadFile} from "../utils/misc";
import {DateToIsoShort} from "../utils/date";

const defaultConfigModule: ConfigModule = {
    liveApiKey: false,
    liveMode: false,
    testApiKey: false,
    webhook: false
}
const defaultConfigPayment: ConfigPayment = {
    mode: test,
    type: STRIPE
}
const defaultSubscriptionItem: SubscriptionPayload = {
    services: [],
    display: false,
    name: '',
    description: '',
    module: "Planner",
    otherSubscriptionConditions: '',
    subscriptionConditions: '',
    plans: [{
        numberOfMonths: 1,
        active: true
    }, {
        numberOfMonths: 3,
        active: true
    }, {
        numberOfMonths: 12,
        active: true
    }],
    unsubscriptionConditions: '',
    currency: 'EUR',
    subscriptions: [],
}

interface PaymentContextProps {
    subscriptionsList: Subscription[];

    fetchSubscriptionList(): Promise<void>;

    loadMoreSubscriptions(): Promise<boolean>;

    configModule: ConfigModule;

    fetchConfigModule(): Promise<void>;

    configPayment: ConfigPayment;

    fetchConfigPayment(): Promise<void>;

    updateConfigPayment(configPayment: ConfigPayment): Promise<void>;

    deleteSubscription(id: string): Promise<void>;

    subscriptionItem: SubscriptionPayload;

    editSubscription(edit: SubscriptionPayload, config: BusinessConfig, id?: string): Promise<void>;

    resetSubscription(): void;

    fetchSubscription(id: string): Promise<void>;

    error: boolean;

    duplicateSubscription(id: string): Promise<void>;

    cancelSubscription(id: string): Promise<void>;

    subscribersList: Subscriber[];

    fetchSubscribersList(subscriptionFormulaId: string, search: string): Promise<void>;

    loadMoreSubscribers(subscriptionFormulaId: string, search: string): Promise<boolean>;

    exportSubscribers(selection: Selection, search: string): Promise<void>;

    subscriber?: Subscriber;

    fetchSubscriberByCustomer(id: string): Promise<void>;

    fetchAllSubscriptions(): Promise<void>;

    sendSubscriptionProposal(body: CustomerSubscriptionPayload): Promise<void>

    cancelSubscriberSubscription(subscriberId: string, subscriptionId: string): Promise<void>
}

const PaymentContext = createContext<PaymentContextProps>({
    subscriptionsList: [],
    fetchSubscriptionList: () => Promise.reject(new Error('Provider not initialized')),
    loadMoreSubscriptions: () => Promise.reject(new Error('Provider not initialized')),
    configModule: defaultConfigModule,
    fetchConfigModule: () => Promise.reject(new Error('Provider not initialized')),
    configPayment: defaultConfigPayment,
    fetchConfigPayment: () => Promise.reject(new Error('Provider not initialized')),
    updateConfigPayment: () => Promise.reject(new Error('Provider not initialized')),
    deleteSubscription: () => Promise.reject(new Error('Provider not initialized')),
    subscriptionItem: defaultSubscriptionItem,
    editSubscription: () => Promise.reject(new Error('Provider not initialized')),
    resetSubscription: () => {
    },
    fetchSubscription: () => Promise.reject(new Error('Provider not initialized')),
    error: false,
    duplicateSubscription: () => Promise.reject(new Error('Provider not initialized')),
    cancelSubscription: () => Promise.reject(new Error('Provider not initialized')),
    fetchSubscribersList: () => Promise.reject(new Error('Provider not initialized')),
    loadMoreSubscribers: () => Promise.reject(new Error('Provider not initialized')),
    subscribersList: [],
    exportSubscribers: () => Promise.reject(new Error('Provider not initialized')),
    fetchSubscriberByCustomer: () => Promise.reject(new Error('Provider not initialized')),
    subscriber: undefined,
    fetchAllSubscriptions: () => Promise.reject(new Error('Provider not initialized')),
    sendSubscriptionProposal: () => Promise.reject(new Error('Provider not initialized')),
    cancelSubscriberSubscription: () => Promise.reject(new Error('Provider not initialized'))
})

export const usePaymentStore = () => useContext<PaymentContextProps>(PaymentContext);

interface PaymentStoreProps {
    codeBouton: string;
    token: string;
}

export const PaymentStore: FC<PaymentStoreProps> = ({children, token, codeBouton}) => {
    const api = useMemo<PaymentApi>(() => new PaymentApi(paymentApi), []);
    const [subscriptionItem, setSubscriptionItem] = useState<SubscriptionPayload>(defaultSubscriptionItem)
    const [subscriptionsList, setSubscriptionsList] = useState<Subscription[]>([]);
    const [subscribersList, setSubscribersList] = useState<Subscriber[]>([]);
    const [configModule, setConfigModule] = useState<ConfigModule>(defaultConfigModule);
    const [configPayment, setConfigPayment] = useState<ConfigPayment>(defaultConfigPayment)
    const [error, setError] = useState<boolean>(false);
    const [subscriber, setSubscriber] = useState<Subscriber>();

    const fetchSubscriptionList = useCallback(async () => {
        const response = await api.getSubscriptionList(codeBouton, token, 10, 0);
        setSubscriptionsList(response.result)
    }, [api, codeBouton, token]);

    const loadMoreSubscriptions = async () => {
        const response = await api.getSubscriptionList(codeBouton, token, 10, subscriptionsList.length)
        setSubscriptionsList([...subscriptionsList, ...response.result]);
        return (subscriptionsList.length + response.count) >= response.total
    }

    const fetchAllSubscriptions = useCallback(async () => {
        let total;
        const limit = 20;
        let subscriptions: Subscription[] = [];
        do {
            const response = await api.getSubscriptionList(codeBouton, token, limit, subscriptions.length);
            if (total === undefined) {
                total = response.total
            }
            subscriptions = [...subscriptions, ...response.result]

        } while (subscriptions.length < total)
        setSubscriptionsList(subscriptions)
    }, [api, codeBouton, token])

    const fetchSubscribersList = async (subscriptionFormulaId: string, search: string) => {
        const response = await api.getSubscribersList(codeBouton, token, 10, 0, subscriptionFormulaId, search);
        setSubscribersList(response.result)
    }

    const loadMoreSubscribers = async (subscriptionFormulaId: string, search: string) => {
        const previousList: Subscriber[] = subscribersList || []
        const response = await api.getSubscribersList(codeBouton, token, 10, previousList.length, subscriptionFormulaId, search)
        setSubscribersList([...previousList, ...response.result])
        return (previousList.length + response.count) >= response.total
    }

    const fetchConfigModule = useCallback(async () => {
        const response = await api.getConfigModule(codeBouton, token);
        setConfigModule(response)
    }, [api, codeBouton, token]);

    const fetchConfigPayment = useCallback(async () => {
        const response = await api.getConfigPayment(codeBouton, token)
        setConfigPayment(response)
    }, [api, codeBouton, token]);

    const updateConfigPayment = async (configPayment: ConfigPayment) => {
        setConfigPayment(await api.updateConfigPayment(codeBouton, token, configPayment))
    }

    const deleteSubscription = async (id: string) => {
        await api.deleteSubscription(codeBouton, token, id)
    }

    const cancelSubscription = async (id: string) => {
        await api.cancelSubscription(codeBouton, token, id)
    }

    const editSubscription = async (edit: SubscriptionPayload, config: BusinessConfig, id?: string,) => {
        let response;
        if (id) {
            response = await api.updateSubscription(codeBouton, token, id, edit, config)
        } else {
            response = await api.createSubscription(codeBouton, token, edit, config)
        }
        setSubscriptionItem(response)
    }

    const resetSubscription = useCallback(() => {
        setSubscriptionItem(defaultSubscriptionItem);
        setError(false)
    }, []);

    const fetchSubscription = useCallback(async (id: string) => {
        try {
            const response = await api.getSubscription(codeBouton, token, id);
            setSubscriptionItem(response)
        } catch (e) {
            console.error(e);
            setError(true)
        }
    }, [api, codeBouton, token]);

    const duplicateSubscription = async (id: string) => {
        const response = await api.getSubscription(codeBouton, token, id);
        setSubscriptionItem({
            name: response.name,
            subscriptions: [],
            display: false,
            locale: response.locale,
            currency: response.currency,
            plans: response.plans,
            unsubscriptionConditions: response.unsubscriptionConditions,
            subscriptionConditions: response.subscriptionConditions,
            otherSubscriptionConditions: response.otherSubscriptionConditions,
            module: response.module,
            description: response.description,
            services: response.services,
            subscriptionFees: response.subscriptionFees,
        })
    }
    const exportSubscribers = async (selection: Selection, search: string) => {
        const response = await api.exportSubscribers(codeBouton, token, selection, search);
        MakeDownloadFile(response, `export_contacts_${DateToIsoShort(new Date())}.csv`)
    }

    const fetchSubscriberByCustomer = useCallback(async (id: string) => {
        const response = await api.getSubscriberByCustomer(codeBouton, token, id);
        if (response.result.length > 0) {
            setSubscriber(response.result[0])
        } else {
            setSubscriber(undefined)
        }
    }, [api, codeBouton, token])

    const sendSubscriptionProposal = async (body: CustomerSubscriptionPayload) => {
        await api.sendSubscriptionProposal(codeBouton, token, body.subscriptionFormulaId, body)
    }

    const cancelSubscriberSubscription = useCallback(async (subscriberId: string, subscriptionId: string) => {
        await api.cancelSubscriberSubscription(codeBouton, token, subscriberId, subscriptionId)
    }, [api, codeBouton, token])

    return <PaymentContext.Provider
        value={{
            subscriptionsList,
            fetchSubscriptionList,
            loadMoreSubscriptions,
            configModule,
            fetchConfigModule,
            configPayment,
            fetchConfigPayment,
            updateConfigPayment,
            deleteSubscription,
            subscriptionItem,
            editSubscription,
            resetSubscription,
            fetchSubscription,
            error,
            duplicateSubscription,
            cancelSubscription,
            fetchSubscribersList,
            loadMoreSubscribers,
            subscribersList,
            exportSubscribers,
            fetchSubscriberByCustomer,
            subscriber,
            fetchAllSubscriptions,
            sendSubscriptionProposal,
            cancelSubscriberSubscription
        }}>
        {children}
    </PaymentContext.Provider>
}