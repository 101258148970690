import {createContext, FC, useCallback, useContext, useMemo, useState} from "react";
import {Category, defaultColor, Service, ServicePayload} from "./services-types";
import {ServicesApi} from "./services-api";
import {appointmentsApi} from "../config/config";

interface ServicesContextProps {
    categoryList: Category[] | null;
    fetchCategoryList: (signal?: AbortSignal) => Promise<void>;
    fetchService: (id: string) => Promise<void>;
    service: ServicePayload;
    editService: (edit: ServicePayload, id?: string) => Promise<void>;
    resetService: () => void;
    error: boolean;
    servicesList: Service[];
    fetchAllServices: () => Promise<void>
}

const defaultService: ServicePayload = {
    title: '',
    description: '',
    agendas: [],
    price: 0,
    duration: 30,
    color: defaultColor,
    waitingListSpots: 0,
    maxSpotsPerBookEntry: 1,
    giftable: false,
    bookable: true,
    spots: 1,
    options: []
}

const ServicesContext = createContext<ServicesContextProps>({
    service: defaultService,
    categoryList: null,
    fetchCategoryList: () => Promise.reject(new Error('Provider not initialized')),
    fetchService: () => Promise.reject(new Error('Provider not initialized')),
    editService: () => Promise.reject(new Error('Provider not initialized')),
    resetService: () => {},
    error: false,
    servicesList: [],
    fetchAllServices: () => Promise.reject(new Error('Provider not initialized')),
})

const fetchAllCategories = async (codeBouton: string, token: string, api: ServicesApi, offset: number, signal?: AbortSignal): Promise<Category[]> => {
    const response = await api.getCategoryList(codeBouton, token, 20, offset, signal);
    if ((offset + response.count) < response.total) {
        return [...response.result, ...await fetchAllCategories(codeBouton, token, api, offset + response.count, signal)]
    }
    return response.result
}

export const useServicesStore = () => useContext(ServicesContext);

interface ServicesStoreProps {
    codeBouton: string;
    token: string;
    paymentActive: boolean
}

export const ServicesStore: FC<ServicesStoreProps> = ({children, codeBouton, token, paymentActive}) => {
    const api = useMemo<ServicesApi>(() => new ServicesApi(appointmentsApi), []);
    const [categoryList, setCategoryList] = useState<Category[] | null>(null);
    const [error, setError] = useState<boolean>(false);
    const [service, setService] = useState<ServicePayload>({
        ...defaultService,
        giftable: paymentActive,
    });
    const [servicesList, setServicesList] = useState<Service[]>([]);

    const fetchService = useCallback(async (id: string) => {
        try {
            const response = await api.getService(codeBouton, token, id);
            setService(response);
        } catch (e) {
            setService({...defaultService, giftable: paymentActive});
            setError(true);
        }
    }, [api, codeBouton, paymentActive, token])

    const editService = async (edit: ServicePayload, id?: string)=> {
        let response: Service;
        if (id) {
            response = await api.updateService(codeBouton, token, id, {...edit})
        } else {
            response = await api.createService(codeBouton, {...edit}, token)
        }
        setService(response)
    }

    const resetService = useCallback(() => {
        setService({...defaultService, giftable: paymentActive});
        setError(false)
    }, [paymentActive])

    const fetchCategoryList = useCallback(async (signal?: AbortSignal) => {
        const result = await fetchAllCategories(codeBouton, token, api, 0, signal);
        setCategoryList(result)
    }, [api, codeBouton, token]);

    const fetchAllServices = useCallback(async () => {
        let total;
        const limit = 20;
        let services: Service[] = [];
        do {
            const response = await api.getServicesList(codeBouton, token, limit, services.length, false, false, false);
            if (total === undefined) {
                total = response.total
            }
            services = [...services, ...response.result]
        } while (services.length < total)
        setServicesList(services)
    }, [api, codeBouton, token])

    return <ServicesContext.Provider value={{
        categoryList,
        fetchCategoryList,
        resetService,
        fetchService,
        service,
        editService,
        error,
        servicesList,
        fetchAllServices
    }}>
        {children}
    </ServicesContext.Provider>
}