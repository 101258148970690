import {FC} from "react";
import {Col, Row, SpanSubtitle2, ThemeType} from "@linkeo.com/ui-lib-react";
import {ReactComponent as CrossIcon} from "../../../icons/cross.svg";
import {ReactComponent as CheckIcon} from "../../../icons/check.svg";
import {useTheme} from "styled-components";

interface StripeValidateCardProps {
    isValid: boolean;
    text: string
}

export const StripeValidateCard: FC<StripeValidateCardProps> = ({isValid, text}) => {
    const theme = useTheme() as ThemeType;

    return <Row style={{marginTop: '6px'}}>
        <Col>
            {isValid
                ? <CheckIcon height={20} width={20}/>
                : <CrossIcon height={20} width={20}/>}
        </Col>
        <Col style={{padding: 0}}>
            <SpanSubtitle2 style={{color: isValid ? theme.colors.grey20 : theme.colors.danger}}>
                {text}
            </SpanSubtitle2>
        </Col>
    </Row>
}