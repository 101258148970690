import React, {FC} from "react";
import {DialogFooter} from "../../../commons/components/dialog-footer";
import {Dialog, SpanBody1} from "@linkeo.com/ui-lib-react";

interface SubscriptionDialogProps {
    onClose: () => void;
    show: boolean;
    onApproveClick: () => void;
    text: string;
    title: string
}

export const SubscriptionDialog: FC<SubscriptionDialogProps> = ({onClose, show, onApproveClick, text, title}) => {

    return <Dialog onClose={onClose}
                   show={show}
                   size={'md'}
                   title={title}
                   footer={<DialogFooter onCancelClick={onClose}
                                         onApproveClick={onApproveClick}/>}>
        <SpanBody1>{text}</SpanBody1>
    </Dialog>
}