import {FC, FormEvent, useEffect, useState} from "react";
import {Card} from "@linkeo.com/ui-lib-react";
import {ParamsFormHeader} from "../params-form-header";
import {useIntl} from "react-intl";
import {SubscriptionInfoForm} from "./subscription-info-form";
import {Service, SubscriptionCustomer, SubscriptionPlan} from "../../payment-types";
import {SubscriptionServicesForm} from "./subscription-services-form";
import {FormFooter} from "../../../commons/components/form-footer";

export interface ServiceItem {
    id: string;
    title: string;
}

export interface SubscriptionFormProps {
    id?: string;
    name: string;
    description: string;
    subscriptionConditions: string;
    unsubscriptionConditions: string;
    otherSubscriptionConditions: string;
    subscriptionFees?: number | null
    plans: Omit<SubscriptionPlan, 'id'>[];
    services: Service[]
    display: boolean;
    currency: string;
    subscriptions: SubscriptionCustomer[]
}

interface SubscriptionEditFormProps {
    form: SubscriptionFormProps;
    servicesList: ServiceItem[];
    onFormClose: () => void;
    onSubmit: (form: SubscriptionFormProps) => void;
    loading: boolean;
    readOnly: boolean;
    title: string;
    subtitle: string;
}

export const SubscriptionEditForm: FC<SubscriptionEditFormProps> = ({
                                                                        form: formProps,
                                                                        servicesList,
                                                                        onFormClose,
                                                                        onSubmit,
                                                                        loading,
                                                                        readOnly,
                                                                        title,
                                                                        subtitle
                                                                    }) => {
    const intl = useIntl();
    const [form, setForm] = useState<SubscriptionFormProps>(formProps);

    useEffect(() => {
        setForm(formProps)
    }, [formProps]);

    const _onSubmit = (e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onSubmit(form)
    }

    return <Card>
        <form onSubmit={_onSubmit}>
            <ParamsFormHeader
                title={title}
                subtitle={subtitle}/>
            <SubscriptionInfoForm form={form} setForm={setForm} readOnly={readOnly}/>
            <SubscriptionServicesForm servicesList={servicesList} form={form} setForm={setForm}/>
            <FormFooter onCancelClick={onFormClose} loading={loading}
                        submitButtonText={intl.formatMessage({id: 'SaveButtonLabel', defaultMessage: 'Enregistrer'})}/>
        </form>
    </Card>
}