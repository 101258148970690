import {ChangeEvent, Dispatch, FC, SetStateAction, useRef, useState} from "react";
import {BaseInput, Button, Col, Dropdown, Row, SpanSubtitle1, Svg, ThemeType} from "@linkeo.com/ui-lib-react";
import {FormattedMessage, useIntl} from "react-intl";
import styled, {useTheme} from "styled-components";
import {Subscription} from "../../params/payment-types";
import {Debounce} from "../../utils/misc";
import {Selection} from '../../commons/common-types'

const SelectRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 16px;
  background-color: ${({theme}) => theme.colors.grey95};
  cursor: pointer;
  border-radius: 3px;
  max-width: 220px;
`
const TextWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-grow: 1;
  margin-right: 10px;
`;
const List = styled.div`
  background-color: ${({theme}) => theme.colors.grey90};
  padding: 12px;
  border-top: 1px solid ${({theme}) => theme.colors.grey80};

  &:hover {
    background-color: ${({theme}) => theme.colors.grey95};
  }
`

interface CustomersNavProps {
    onSelectClick: (value: keyof Selection) => void;
    getSelection: keyof Selection;
    subscriptionsList: Subscription[];
    onSelectSubscriptionList: (subscription?: Subscription) => void;
    onSearch: (search: string) => void;
    searchValue: string;
    setSearchValue: Dispatch<SetStateAction<string>>;
    exportLoading: boolean;
    onExportClick: () => void;
}

export const CustomersNav: FC<CustomersNavProps> = ({
                                                        onSelectClick,
                                                        subscriptionsList,
                                                        onSelectSubscriptionList,
                                                        onSearch,
                                                        setSearchValue,
                                                        searchValue,
                                                        getSelection,
                                                        onExportClick,
                                                        exportLoading
                                                    }) => {
    const theme = useTheme() as ThemeType;
    const intl = useIntl();
    const [show, setShow] = useState<boolean>(false);
    const [selected, setSelected] = useState<string | undefined>();
    const wrapperRef = useRef(null);
    const debounceRef = useRef<number>();

    const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setSearchValue(value);
        debounceRef.current = Debounce(() => {
            onSearch(value)
        }, 500, debounceRef.current || 0)
    }

    const onSelectList = (subscription?: Subscription) => {
        setSelected(subscription?.name);
        onSelectSubscriptionList(subscription)
    }

    return <div style={{backgroundColor: theme.colors.grey80, padding: '8px'}}>
        <Row justifyContent={'center'} alignItems={'center'} style={{rowGap: '15px'}}>
            <Col>
                <SelectRow
                    ref={wrapperRef}
                    onMouseEnter={() => setShow(true)}
                    onClick={() => setShow(true)}
                    onMouseLeave={() => setShow(false)}>
                    <TextWrapper>
                        <SpanSubtitle1 style={{color: theme.colors.grey60}}>
                            {selected ??
                                <FormattedMessage id={"CustomersNavMembershipTitle"} defaultMessage={'Abonnements:'}/>}
                        </SpanSubtitle1>
                    </TextWrapper>
                    <Svg icon={"down"} width={20} height={20} style={{marginLeft: "10px", flexShrink: 0}}
                         fill={theme.colors.grey60}/>
                    <Dropdown show={show} onClose={() => setShow(false)} wrapperRef={wrapperRef}>
                        <List onClick={() => onSelectList(undefined)}>
                            <SpanSubtitle1 style={{color: theme.colors.grey60}}>
                                <FormattedMessage id={'customersNavDropdownNone'} defaultMessage={'Aucun'}/>
                            </SpanSubtitle1>
                        </List>
                        {subscriptionsList.map((el, index) => <List key={index} onClick={() => onSelectList(el)}>
                            <SpanSubtitle1 style={{color: theme.colors.grey60}}>{el.name}</SpanSubtitle1>
                        </List>)}
                    </Dropdown>
                </SelectRow>
            </Col>
            <Col>
                <BaseInput
                    placeholder={intl.formatMessage({
                        id: 'CustomersNavSearchInputPlaceholder',
                        defaultMessage: 'Rechercher'
                    })}
                    onChange={onSearchChange}
                    value={searchValue}
                />
            </Col>
            <Col>
                <Button colorType="outline"
                        onClick={() => onSelectClick(getSelection === "exclude" ? "include" : "exclude")}
                        style={{marginRight: '16px'}}>
                    {getSelection === "exclude"
                        ? <FormattedMessage id={'CustomersNavSelectButtonTitleUnselect'}
                                            defaultMessage={'Tout désélectionner'}/>
                        : <FormattedMessage id={'CustomersNavSelectButtonTitleSelect'}
                                            defaultMessage={'Tout sélectionner'}/>}
                </Button>
                <Button colorType="primary" style={{marginLeft: '16px'}} loading={exportLoading}
                        onClick={onExportClick}>
                    <FormattedMessage id={'CustomersNavExportButtonTitle'} defaultMessage={'Exporter'}/>
                </Button>
            </Col>
        </Row>
    </div>
}