import {FC, useEffect, useState} from "react";
import {PageWrapper} from "../../../commons/components/page-wrapper";
import {Container, useToaster} from "@linkeo.com/ui-lib-react";
import {
    SubscriptionEditForm,
    SubscriptionFormProps
} from "../../../params/components/subscriptions/subscription-edit-form";
import {usePaymentStore} from "../../../params/payment-store";
import {useServicesStore} from "../../../services/services-store";
import {useNavigate, useParams} from "react-router";
import {routeParamsSubscriptions} from "../../../routes";
import {useIntl} from "react-intl";
import {Error404} from "../../../commons/components/404-error";
import {useConfigurationStore} from "../../../params/configuration-store";
import {FRONT_URI} from "../../../config/config";
import {useAuthStore} from "../../../providers/sso/auth-provider";
import {AxiosError} from "axios";

export const ParamsSubscriptionEditPage: FC = () => {
    const navigate = useNavigate();
    const toast = useToaster();
    const {id} = useParams();
    const {locale, ...intl} = useIntl();
    const {codeBouton} = useAuthStore();
    const {subscriptionItem, editSubscription, resetSubscription, fetchSubscription, error} = usePaymentStore();
    const {fetchAllServices, servicesList} = useServicesStore();
    const {config} = useConfigurationStore();
    const [loading, setLoading] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        Promise.all([
            id ? fetchSubscription(id) : Promise.resolve(null),
            fetchAllServices()
        ]).catch(e => console.error(e))
            .finally(() => setLoading(false))
        return () => resetSubscription()
    }, [fetchAllServices, fetchSubscription, id, resetSubscription]);

    const onSubscriptionFormSubmit = async (form: SubscriptionFormProps) => {
        if (!form.plans.find(el => el.active && el.price! > 0)) {
            toast(intl.formatMessage({
                id: 'paramsSubscriptionEditPageSubmitPriceError',
                defaultMessage: 'Vous devez activer au moins un plan tarifaire avec un tarif supérieur à 0'
            }))
            return;
        }
        if (form.services.length === 0) {
            toast(intl.formatMessage({
                id: 'paramsSubscriptionEditPageSubmitServicesError',
                defaultMessage: 'Vous devez sélectionner au moins une prestation à associer à cet abonnement'
            }))
            return;
        }
        setIsLoading(true);
        try {
            await editSubscription({...form, plans: form.plans.map(el => {
                        if (!el.price || el.price <= 0) {
                            return {...el, active: false}
                        } else {
                            return el
                        }
                    }), locale},
                {
                    businessName: config.name,
                    businessEmail: config.email || '',
                    businessPhone: config.phone || '',
                    frontSubscriptionFormulasUri: `${FRONT_URI}/${codeBouton}/${locale}/subscription`,
                    frontTakeAppointmentUri: `${FRONT_URI}/${codeBouton}/${locale}/appointment`
                }, id);
            navigate(routeParamsSubscriptions);
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data.statusCode === 519) {
                toast(intl.formatMessage({
                    id: 'errorMessageStripe',
                    defaultMessage: 'Vous devez configurer Stripe pour pouvoir proposer des abonnements. {br}Veuillez vous rendre sur l\'onglet Paiement dans vos Paramètres.'
                }, {br: <br/>}))
            } else {
                toast(intl.formatMessage({id: 'errorMessage', defaultMessage: 'Une erreur est survenue'}))
            }
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    }

    return <PageWrapper isLoading={loading}>
        {!error ? <Container size={'lg'}>
            <SubscriptionEditForm
                title={id ? subscriptionItem.name : intl.formatMessage({
                    id: 'ParamsSubscriptionCreateFormTitle',
                    defaultMessage: 'Ajouter un nouvel abonnement'
                })}
                subtitle={id ? subscriptionItem.subscriptions.length === 0
                        ? intl.formatMessage({
                            id: 'ParamsSubscriptionEditFormSubTitle',
                            defaultMessage: 'Modifiez ici votre abonnement'
                        })
                        : intl.formatMessage({
                            id: 'ParamsSubscriptionEditReadonlyFormSubTitle',
                            defaultMessage: 'Modifiez la liste des prestations auxquelles auront accès vos abonnés'
                        })
                    : intl.formatMessage({
                        id: 'ParamsSubscriptionCreateFormSubTitle',
                        defaultMessage: 'Ajoutez et paramétrez ici votre abonnement'
                    })}
                form={subscriptionItem}
                readOnly={!!id && subscriptionItem.subscriptions.length > 0}
                servicesList={servicesList}
                onFormClose={() => navigate(routeParamsSubscriptions)}
                onSubmit={onSubscriptionFormSubmit}
                loading={isLoading}
            />
        </Container> : <Error404/>}
    </PageWrapper>
}