import {Dispatch, FC, FormEvent, SetStateAction} from "react";
import {Button, Col, Input, Row, Select, SelectOption} from "@linkeo.com/ui-lib-react";
import {FormattedMessage, useIntl} from "react-intl";
import {
    CustomerSubscriptionPayload,
    SubscriberSubscription,
    Subscription,
    SubscriptionPlan
} from "../../params/payment-types";

interface CustomerSubscriptionFormProps {
    form?: CustomerSubscriptionPayload;
    setForm: Dispatch<SetStateAction<CustomerSubscriptionPayload | undefined>>;
    onSubmit: () => void;
    subscriptionsOptions: SelectOption<Subscription>[];
    onCancelClick: () => void;
    plansOptions: SelectOption<SubscriptionPlan>[] | undefined;
    loading: boolean;
    selectedSubscription?: SubscriberSubscription;
    getFrequencyTitle: (number: number) => string;
    frontSubscribeBaseUri: string;
}

export const CustomerSubscriptionForm: FC<CustomerSubscriptionFormProps> = ({
                                                                                form,
                                                                                setForm,
                                                                                onSubmit,
                                                                                subscriptionsOptions,
                                                                                onCancelClick,
                                                                                plansOptions,
                                                                                loading,
                                                                                selectedSubscription,
                                                                                getFrequencyTitle,
                                                                                frontSubscribeBaseUri
                                                                            }) => {
    const intl = useIntl();

    const _onSubmit = (e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onSubmit()
    }

    return <form onSubmit={_onSubmit}>
        <Row>
            <Col columns={[12, 12, 6]}>
                {form ? <Select options={subscriptionsOptions}
                                selected={subscriptionsOptions.find(el => el.value.id === form.subscriptionFormulaId)}
                                onSelect={(select) => setForm({
                                    ...form,
                                    subscriptionFormulaId: select.value.id,
                                    planId: select.value.plans[0].id,
                                    frontSubscribeBaseUri: `${frontSubscribeBaseUri}/${select.value.id}/${select.value.plans[0].id}`
                                })}
                                label={intl.formatMessage({
                                    id: 'customerSubscriptionFormSubscriptionNameSelectLabel',
                                    defaultMessage: 'Ajouter un nouvel abonnement'
                                })}/>
                    : selectedSubscription ?
                        <Input readOnly value={selectedSubscription.name} label={intl.formatMessage({
                            id: 'ParamsSubscriptionInfoFormNameInputLabel',
                            defaultMessage: 'Nom de l\'abonnement'
                        })}/> : null}
                <div style={{width: '50%'}}>
                    {form ? <Select options={plansOptions}
                                    selected={plansOptions?.find(el => el.value.id === form.planId)}
                                    onSelect={(select => setForm({
                                        ...form, 
                                        planId: select.value.id, 
                                        frontSubscribeBaseUri: `${frontSubscribeBaseUri}/${form.subscriptionFormulaId}/${select.value.id}`
                                    }))}
                                    label={intl.formatMessage({
                                        id: 'ParamsSubscriptionInfoFormPlansTitle',
                                        defaultMessage: 'Plans Tarifaires'
                                    })}/>
                        : selectedSubscription ?
                            <Input readOnly value={`${intl.formatNumber(selectedSubscription.plan.price || 0, {
                                style: 'currency',
                                currency: subscriptionsOptions.find(el => el.value.id === selectedSubscription.id)?.value.currency || 'EUR',
                                minimumFractionDigits: (selectedSubscription.plan.price || 0) % 1 === 0 ? 0 : ((selectedSubscription.plan.price || 0) % 1).toString().length <= 3 ? 1 : 2
                            })} (${getFrequencyTitle(selectedSubscription.plan.numberOfMonths)})`}
                                   label={intl.formatMessage({
                                       id: 'ParamsSubscriptionInfoFormPlansTitle',
                                       defaultMessage: 'Plans Tarifaires'
                                   })}/> : null}
                </div>
                <Input disabled={!!form} readOnly={!!selectedSubscription}
                       value={subscriptionsOptions.find(el => el.value.id === selectedSubscription?.subscriptionFormulaId)?.value.subscriptionConditions}
                       placeholder={'-'} label={intl.formatMessage({
                    id: 'customerSubscriptionFormSubscriptionConditionsInputLabel',
                    defaultMessage: 'Les conditions d’inscription'
                })}/>
                <Input disabled={!!form} readOnly={!!selectedSubscription}
                       value={subscriptionsOptions.find(el => el.value.id === selectedSubscription?.subscriptionFormulaId)?.value.unsubscriptionConditions}
                       placeholder={'-'} label={intl.formatMessage({
                    id: 'customerSubscriptionFormCancelSubscriptionConditionsInputLabel',
                    defaultMessage: 'Les conditions de désinscription'
                })}/>
            </Col>
            <Col columns={[12, 12, 6]}>
                <Input disabled={!!form} readOnly={!!selectedSubscription}
                       value={selectedSubscription?.activatedAt ? intl.formatDate(selectedSubscription.activatedAt, {dateStyle: 'short'}) : undefined}
                       placeholder={intl.formatMessage({
                           id: 'customerSubscriptionFormStartDatePlaceholder',
                           defaultMessage: 'Durée de l’abonnement'
                       })}
                       label={intl.formatMessage({
                           id: 'customerSubscriptionFormStartDateInputLabel',
                           defaultMessage: 'Date du début d’abonnement'
                       })}/>
                <Input disabled={!!form} readOnly={!!selectedSubscription}
                       value={selectedSubscription?.currentPeriodEnd ? intl.formatDate(selectedSubscription.currentPeriodEnd, {dateStyle: 'short'}) : undefined}
                       placeholder={intl.formatMessage({
                           id: 'customerSubscriptionFormPaymentEndDatePlaceholder',
                           defaultMessage: 'Pas de règlement pour l’instant'
                       })}
                       label={intl.formatMessage({
                           id: 'customerSubscriptionFormPaymentEndDateInputLabel',
                           defaultMessage: 'Date du dernier règlement'
                       })}/>
            </Col>
            <Col columns={[12, 12, 6]} style={{display: "flex", justifyContent: 'flex-end'}}>
                {!selectedSubscription && form && <Button colorType={'primary'} type={'submit'} loading={loading}>
                    <FormattedMessage id={'customerSubscriptionFormProposalSubmitLabel'}
                                      defaultMessage={'Envoyer l’offre au client'}/>
                </Button>}
            </Col>
        </Row>
        <Row justifyContent={'flex-end'} style={{marginTop: '100px'}}>
            <Col>
                <Button style={{marginRight: '10px'}} onClick={onCancelClick}>
                    <FormattedMessage id={'CancelButtonLabel'} defaultMessage={'Annuler'}/>
                </Button>
                <Button colorType={'primary'} disabled>
                    <FormattedMessage id={'SaveButtonLabel'} defaultMessage={'Enregistrer'}/>
                </Button>
            </Col>
        </Row>
    </form>
}