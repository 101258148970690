import {createContext, FC, useCallback, useContext, useMemo, useState} from "react";
import {Configuration, PaymentConfig, test} from './configuration-types';
import {ConfigurationApi} from "./configuration-api";
import {configurationApi} from "../config/config";

const defaultConfiguration: Configuration = {
    appointment: {
        paymentActive: false
    },
    giftcard : {
        active: false
    },
    paymentPercent: 100,
    currency: 'EUR',
    name: ''
}

const defaultPaymentConfig: PaymentConfig = {
    mode: test
}

interface ConfigurationContextProps {
    config: Configuration;
    paymentConfig: PaymentConfig;
    fetchData: (signal?: AbortSignal) => Promise<void>;
    updateConfig: (config: Configuration) => Promise<void>;
    fetchPaymentData: () => Promise<void>;
    updatePaymentConfig: (paymentConfig: PaymentConfig) => Promise<void>;
}

const ConfigurationContext = createContext<ConfigurationContextProps>({
    config: defaultConfiguration,
    paymentConfig: defaultPaymentConfig,
    fetchData: () => Promise.reject(new Error('Provider not initialized')),
    updateConfig: () => Promise.reject(new Error('Provider not initialized')),
    fetchPaymentData: () => Promise.reject(new Error('Provider not initialized')),
    updatePaymentConfig: () => Promise.reject(new Error('Provider not initialized'))
})

export const useConfigurationStore = () => useContext(ConfigurationContext);

export const ConfigurationStore: FC<{codeBouton: string, token: string}> = ({children, codeBouton, token}) => {
    const api = useMemo<ConfigurationApi>(() => new ConfigurationApi(configurationApi), [])
    const [config, setConfig] = useState<Configuration>(defaultConfiguration);
    const [paymentConfig, setPaymentConfig] = useState<PaymentConfig>(defaultPaymentConfig)

    const fetchData = useCallback(async(signal?: AbortSignal) => {
        const result = await api.getConfiguration(codeBouton, signal)
        setConfig(result)
    }, [api, codeBouton]);

    const updateConfig = async (config: Configuration) => {
        setConfig(await api.updateConfiguration(codeBouton, token, config))
    }

    const fetchPaymentData = useCallback(async () => {
        const result = await api.getPayment(codeBouton, token)
        setPaymentConfig(result)
    }, [api, codeBouton, token])

    const updatePaymentConfig = async (paymentConfig: PaymentConfig) => {
        setPaymentConfig(await api.updatePaymentConfig(codeBouton, token, paymentConfig))
    }

    return <ConfigurationContext.Provider value={{config, fetchData, updateConfig, paymentConfig, fetchPaymentData, updatePaymentConfig}}>
        {children}
    </ConfigurationContext.Provider>
}