import {FC, FormEvent, useState} from "react";
import {Card, Col, Input, Radio, Row, SpanLink, SpanSubtitle2, ThemeType} from "@linkeo.com/ui-lib-react";
import {ReactComponent as StripeIcon} from "../../../icons/stripe.svg";
import {FormattedMessage, useIntl} from "react-intl";
import {useTheme} from "styled-components";
import {live, Mode, PaymentConfig, test} from "../../configuration-types";
import {StripeValidateCard} from "./stripe-validate-card";
import {ConfigModule} from "../../payment-types";
import {FormFooter} from "../../../commons/components/form-footer";

interface PaymentStripeFormProps {
    mode: Mode;
    pk_test?: string;
    sk_test?: string;
    pk_live?: string;
    sk_live?: string;
    configModule: ConfigModule;
    onSubmit: (config: PaymentConfig) => void;
    loading: boolean
}

interface StripeKey extends Omit<PaymentConfig, 'mode'> {
}

export const PaymentStripeForm: FC<PaymentStripeFormProps> = ({
                                                                  mode: modeProps,
                                                                  pk_test: pk_test_props,
                                                                  sk_test: sk_test_props,
                                                                  sk_live: sk_live_props,
                                                                  pk_live: pk_live_props,
                                                                  configModule,
                                                                  onSubmit,
                                                                  loading
                                                              }) => {
    const theme = useTheme() as ThemeType;
    const intl = useIntl();
    const [stripeKeys, setStripeKeys] = useState<StripeKey>({
        pk_test: pk_test_props,
        sk_test: sk_test_props,
        pk_live: pk_live_props,
        sk_live: sk_live_props
    });
    const [mode, setMode] = useState<Mode>(modeProps)

    const onStripeKeyChange = (val: string, key: keyof PaymentConfig) => {
        setStripeKeys(prevState => {
            const updatedState: StripeKey = {...prevState};
            (Object.keys(updatedState) as (keyof StripeKey)[]).forEach(key => {
                if (updatedState[key] === '') {
                    updatedState[key] = undefined;
                }
                return updatedState;
            })
            return {...updatedState, [key]: val === '' ? undefined : val}
        })
    }

    const restoreValues = () => {
        setStripeKeys({
            pk_test: pk_test_props,
            sk_test: sk_test_props,
            pk_live: pk_live_props,
            sk_live: sk_live_props
        })
        setMode(modeProps)
    }

    const _onSubmit = (e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onSubmit({...stripeKeys, mode})
    }

    return <Card style={{marginTop: '28px'}}>
        <form onSubmit={_onSubmit}>
            <Row>
                <Col columns={[12, 12, 6]}>
                    <StripeValidateCard isValid={configModule.testApiKey} text={intl.formatMessage({
                        id: 'paymentStripeFormTestKeyLabel',
                        defaultMessage: 'Clés de test valides'
                    })}/>
                    <StripeValidateCard isValid={configModule.liveApiKey} text={intl.formatMessage({
                        id: 'paymentStripeFormLiveKeyLabel',
                        defaultMessage: 'Clés live valides'
                    })}/>
                    <StripeValidateCard isValid={configModule.webhook} text={intl.formatMessage({
                        id: 'paymentStripeFormWebhookLabel',
                        defaultMessage: 'Webhook installé'
                    })}/>
                    <StripeValidateCard isValid={configModule.liveMode} text={intl.formatMessage({
                        id: 'paymentStripeFormLiveModeLabel',
                        defaultMessage: 'Mode live sélectionné'
                    })}/>
                </Col>
                <Col columns={[12, 12, 6]}>
                    <StripeIcon/>
                    <div>
                        <SpanSubtitle2>
                            <FormattedMessage
                                id={'ParamsPaymentStripeTitle'}
                                defaultMessage={'Le paiement en ligne est assuré par Stripe. {br}Stripe prélève 1.4% + 0,25cts par transaction effectuée.'}
                                values={{br: <br/>}}
                            />
                        </SpanSubtitle2>
                    </div>
                    <a href={'https://dashboard.stripe.com/dashboard'} target={'_blank'} rel="noreferrer">
                        <SpanLink>
                            <FormattedMessage
                                id={'ParamsPaymentStripeLinkLabel'}
                                defaultMessage={'Accéder à stripe.com'}
                            />
                        </SpanLink>
                    </a>
                </Col>
            </Row>
            <div style={{marginTop: '28px', paddingTop: '28px', borderTop: `1px solid ${theme.colors.grey90}`}}>
                <SpanSubtitle2>
                    <FormattedMessage
                        id={'ParamsPaymentStripeFormTitle'}
                        defaultMessage={'Pour activer défintivement le paiement sur votre site. {br}Merci de renseigner les informations relatives à votre compte Stripe ci-dessous.'}
                        values={{br: <br/>}}
                    />
                </SpanSubtitle2>
                <Row style={{marginTop: '26px', marginBottom: '25px'}}>
                    <Col columns={[12, 12, 6]}>
                        <Radio
                            name={'ParamsPaymentStripeRadio'}
                            onChange={() => setMode(test)}
                            value={mode === test}>
                            <FormattedMessage
                                id={'ParamsPaymentStripeRadioLabelTestMode'}
                                defaultMessage={'Mode test'}
                            />
                        </Radio>
                        <Input
                            type={'text'}
                            placeholder={'pk_test_XXXXXXXXXXXXXXXXXXXXX'}
                            required
                            disabled={mode === live}
                            value={stripeKeys.pk_test}
                            onChange={(pk_test) => onStripeKeyChange(pk_test, 'pk_test')}
                            label={intl.formatMessage({id: 'PublishableKey', defaultMessage: 'Publishable key'})}
                        />
                        <Input
                            type={'text'}
                            placeholder={'sk_test_XXXXXXXXXXXXXXXXXXXXX'}
                            required
                            disabled={mode === live}
                            value={stripeKeys.sk_test}
                            onChange={(sk_test) => onStripeKeyChange(sk_test, 'sk_test')}
                            label={intl.formatMessage({id: 'SecretKey', defaultMessage: 'Secret key'})}
                        />
                    </Col>
                    <Col columns={[12, 12, 6]}>
                        <Radio
                            name={'ParamsPaymentStripeRadio'}
                            onChange={() => setMode(live)}
                            value={mode === live}>
                            <FormattedMessage
                                id={'ParamsPaymentStripeRadioLabelLiveMode'}
                                defaultMessage={'Mode live'}
                            />
                        </Radio>
                        <Input
                            type={'text'}
                            placeholder={'pk_live_XXXXXXXXXXXXXXXXXXXXX'}
                            required
                            disabled={mode === test}
                            value={stripeKeys.pk_live}
                            onChange={(pk_live) => onStripeKeyChange(pk_live, 'pk_live')}
                            label={intl.formatMessage({id: 'PublishableKey', defaultMessage: 'Publishable key'})}
                        />
                        <Input
                            type={'text'}
                            placeholder={'sk_live_XXXXXXXXXXXXXXXXXXXXX'}
                            required
                            disabled={mode === test}
                            value={stripeKeys.sk_live}
                            onChange={(sk_live) => onStripeKeyChange(sk_live, 'sk_live')}
                            label={intl.formatMessage({id: 'SecretKey', defaultMessage: 'Secret key'})}
                        />
                    </Col>
                </Row>
                <FormFooter
                    loading={loading}
                    onCancelClick={restoreValues}
                    submitButtonText={intl.formatMessage({id: 'SaveButtonLabel', defaultMessage: 'Enregistrer'})}
                />
            </div>
        </form>
    </Card>
}