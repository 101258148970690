import axios, {AxiosRequestHeaders} from "axios";
import {ResultPagination, Selection} from "../commons/common-types";
import {
    BusinessConfig,
    ConfigModule,
    ConfigPayment,
    CustomerSubscriptionPayload,
    Subscriber,
    Subscription,
    SubscriptionPayload
} from "./payment-types";

export class PaymentApi {
    public constructor(private apiBaseUri: string) {
    }

    async getSubscriptionList(codeBouton: string, token: string, limit: number, offset: number): Promise<ResultPagination<Subscription>> {
        const headers: AxiosRequestHeaders = {Authorization: `Bearer ${token}`};
        const response = await axios.get(`${this.apiBaseUri}/${codeBouton}/subscriptionFormula?module=Planner&limit=${limit}&offset=${offset}&status=active,canceled`, {headers})
        return response.data
    }

    async getSubscribersList(codeBouton: string, token: string, limit: number, offset:number, subscriptionFormulaId: string, search: string): Promise<ResultPagination<Subscriber>> {
        const headers: AxiosRequestHeaders = {Authorization: `Bearer ${token}`};
        const response = await axios.get(`${this.apiBaseUri}/${codeBouton}/subscriber?module=Planner&limit=${limit}&offset=${offset}&subscriptionFormulaId=${subscriptionFormulaId}&search=${search}`, {headers})
        return response.data
    }

    async getConfigModule(codeBouton: string, token: string): Promise<ConfigModule> {
        const headers: AxiosRequestHeaders = {Authorization: `Bearer ${token}`};
        const response = await axios.get(`${this.apiBaseUri}/${codeBouton}/configuration/Planner?type=STRIPE`, {headers})
        return response.data
    }

    async getConfigPayment(codeBouton: string, token: string): Promise<ConfigPayment> {
        const headers: AxiosRequestHeaders = {Authorization: `Bearer ${token}`};
        const response = await axios.get(`${this.apiBaseUri}/${codeBouton}/configuration`, {headers})
        return response.data
    }

    async updateConfigPayment(codeBouton: string, token: string, config: ConfigPayment): Promise<ConfigPayment> {
        const headers: AxiosRequestHeaders = {Authorization: `Bearer ${token}`};
        const response = await axios.put(`${this.apiBaseUri}/${codeBouton}/configuration/Planner`, config, {headers});
        return response.data
    }

    async deleteSubscription(codeBouton: string, token: string, id: string): Promise<void> {
        const headers: AxiosRequestHeaders = {Authorization: `Bearer ${token}`};
        await axios.delete(`${this.apiBaseUri}/${codeBouton}/subscriptionFormula/${id}`, {headers})
    }

    async cancelSubscription(codeBouton: string, token: string, id: string): Promise<Subscription> {
        const headers: AxiosRequestHeaders = {Authorization: `Bearer ${token}`};
        const body = {status: 'canceled'}
        const response = await axios.patch(`${this.apiBaseUri}/${codeBouton}/subscriptionFormula/${id}`, body, {headers})
        return response.data
    }

    async createSubscription(codeBouton: string, token: string, body: SubscriptionPayload, config: BusinessConfig): Promise<Subscription> {
        const headers: AxiosRequestHeaders = {Authorization: `Bearer ${token}`};
        const response = await axios.post(`${this.apiBaseUri}/${codeBouton}/subscriptionFormula`, {...body, ...config}, {headers})
        return response.data
    }

    async updateSubscription(codeBouton: string, token: string, id: string, edit: SubscriptionPayload, config: BusinessConfig): Promise<Subscription> {
        const headers: AxiosRequestHeaders = {Authorization: `Bearer ${token}`};
        const response = await axios.put(`${this.apiBaseUri}/${codeBouton}/subscriptionFormula/${id}`, {...edit, ...config}, {headers})
        return response.data
    }

    async getSubscription(codeBouton: string, token: string, id: string): Promise<Subscription> {
        const headers: AxiosRequestHeaders = {Authorization: `Bearer ${token}`};
        const response = await axios.get(`${this.apiBaseUri}/${codeBouton}/subscriptionFormula/${id}`, {headers});
        return response.data
    }

    async exportSubscribers(codeBouton: string, token: string, body: Selection, search: string): Promise<any> {
        const headers: AxiosRequestHeaders = {Authorization: `Bearer ${token}`, 'Content-Type': 'application/json', 'Accept': 'text/html'};
        const response = await axios.post(`${this.apiBaseUri}/${codeBouton}/export/csv?search=${search}`, body, {headers, responseType: 'text'})
        return response.data
    }

    async getSubscriberByCustomer(codeBouton: string, token: string, customerId: string): Promise<ResultPagination<Subscriber>> {
        const headers: AxiosRequestHeaders = {Authorization: `Bearer ${token}`};
        const response = await axios.get(`${this.apiBaseUri}/${codeBouton}/subscriber?customerId=${customerId}`, {headers})
        return response.data
    }

    async sendSubscriptionProposal(codeBouton: string, token: string, id: string, body: CustomerSubscriptionPayload): Promise<void> {
        const headers: AxiosRequestHeaders = {Authorization: `Bearer ${token}`};
        await axios.post(`${this.apiBaseUri}/${codeBouton}/subscriptionFormula/${id}/propose`, body, {headers})
    }

    async cancelSubscriberSubscription(codeBouton: string, token: string, subscriberId: string, subscriptionId: string): Promise<Subscription> {
        const headers: AxiosRequestHeaders = {Authorization: `Bearer ${token}`};
        const response = await axios.delete(`${this.apiBaseUri}/${codeBouton}/subscriber/${subscriberId}/subscription/${subscriptionId}`, {headers});
        return response.data
    }
}