import {FC, useState} from "react";
import styled from "styled-components";

const NavContainer = styled.ul`
  position: relative;
  width: calc(100% + 76px);
  display: flex;
  padding: 0 38px;
  border-top: 1px solid ${({theme}) => theme.colors.grey90};
  border-bottom: 1px solid ${({theme}) => theme.colors.grey90};
  font-weight: 600;
  left: -38px;
  right: -38px;
  align-items: center;
  list-style: none;
`
const NavItem = styled.li<{ active: boolean }>`
  position: relative;
  height: 100%;
  vertical-align: middle;
  margin-right: 50px;
  line-height: 50px;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  list-style: none;
  font-family: 'Raleway', sans-serif;
  transition: all .2s ease;
  color: ${({theme, active}) => active ? theme.colors.primary : theme.colors.grey40};
  border-bottom: ${({active, theme}) => active ? `3px solid ${theme.colors.primary}` : 'none'};
  bottom: ${({active}) => active ? '-1px' : 0};
`

interface CustomerTabContentProps {
    tabs: { title: string, pathname?: string, content: JSX.Element | null }[];
    basePath: string
}

export const CustomerTabContent: FC<CustomerTabContentProps> = ({tabs, basePath}) => {
    const [selectedIndex, setSelectedIndex] = useState<number>(4);

    const onTabClick = (index: number, pathname?: string) => {
        setSelectedIndex(index);
        if (pathname) {
            window.location.pathname = basePath + pathname
        }
    }

    return <>
        <NavContainer>
            {tabs.map((tab, index) => {
                return <NavItem key={index} active={selectedIndex === index}
                                onClick={() => onTabClick(index, tab.pathname)}>
                    {tab.title}
                </NavItem>
            })}
        </NavContainer>
        {tabs[selectedIndex].content}
    </>
}