import React, {FC} from 'react';
import styled from 'styled-components';
import {
    ExcludeChildrenByType,
    LoadingContent, QueryChildrenByType, TopSticky,
} from '@linkeo.com/ui-lib-react';

interface PageWrapperProps {
    isLoading?: boolean;
}

const Main = styled.main`
    background: ${({theme}) => theme.colors.invertPrimary};
    min-height: calc(100vh - 176px);
    padding: 0 48px;
    position: relative;
    letter-spacing: .5px;

    @media screen and (max-width: 760px) {
        padding: 0 16px;
    }
`

export const PageWrapperSubHeader: FC = ({children}) => {
    return <>{children}</>;
};
export const PageWrapper: FC<PageWrapperProps> = ({children, isLoading}) => {
    const appendHeader = QueryChildrenByType(children, PageWrapperSubHeader);
    const filteredChildren = ExcludeChildrenByType(children, [PageWrapperSubHeader]);

    return (
        <div>
            <TopSticky>
                {appendHeader}
            </TopSticky>
            <LoadingContent isLoading={!!isLoading}>
                <Main id={'main'}>{filteredChildren}</Main>
            </LoadingContent>
        </div>
    );
};
