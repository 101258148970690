import {Route, Routes} from "react-router";
import {
    routeAgendas,
    routeCustomersIndex, routeDateAgendas, routeEvents,
    routeGiftcardCreate,
    routeGiftcards, routeHome,
    routeOptions,
    routeOptionsCreate,
    routeOptionsEdit,
    routeParams,
    routeParamsAgendas,
    routeParamsBooking,
    routeParamsClosures,
    routeParamsDiscounts,
    routeParamsDiscountsCreate,
    routeParamsDiscountsEdit,
    routeParamsGiftcardsDiscounts,
    routeParamsGiftcardsDiscountsCreate,
    routeParamsGiftcardsDiscountsEdit,
    routeParamsNotifications,
    routeParamsPayment,
    routeParamsPersonalization,
    routeServiceIndex, routeSubscriptionIndex,
} from "./routes";
import {OptionsListPage} from "./pages/options/options-list-page";
import {OptionsEditPage} from "./pages/options/options-edit-page";
import { GiftcardsListPage } from "./pages/giftcards/giftcards-list-page";
import {GiftcardCreatePage} from "./pages/giftcards/giftcard-create-page";
import {EventsPage} from "./pages/events-page";
import {DateAgendasPage} from "./pages/date-agendas-page";
import { ParamsDiscountEditPage } from "./pages/params/discounts/params-discount-edit-page";
import { ParamsDiscountsListPage } from "./pages/params/discounts/params-discounts-list-page";
import { ParamsAgendasPage } from "./pages/params/params-agendas-page";
import { ParamsBookingPage } from "./pages/params/params-booking-page";
import { ParamsClosuresPage } from "./pages/params/params-closures-page";
import { ParamsNotificationsPage } from "./pages/params/params-notifications-page";
import { ParamsPage } from "./pages/params/params-page";
import { ParamsPaymentPage } from "./pages/params/params-payment-page";
import { ParamsPersonalizationPage } from "./pages/params/params-personalization-page";
import {HomePage} from "./pages/home-page";
import {ServicesIndexPage} from "./pages/services/services-index-page";
import {SubscriptionIndexPage} from "./pages/params/subscriptions/subscription-index-page";
import { CustomersIndexPage } from "./pages/customers/customers-index-page";

export const Router = () => {

    return <Routes>
        <Route path={routeHome} element={<HomePage />}/>

        <Route path={routeOptions} element={<OptionsListPage/>}/>
        <Route path={routeOptionsCreate} element={<OptionsEditPage/>}/>
        <Route path={routeOptionsEdit} element={<OptionsEditPage/>}/>

        <Route path={routeCustomersIndex} element={<CustomersIndexPage/>}/>

        <Route path={routeGiftcards} element={<GiftcardsListPage />} />
        <Route path={routeGiftcardCreate} element={<GiftcardCreatePage />} />

        <Route path={routeParams} element={<ParamsPage />}/>
        <Route path={routeParamsBooking} element={<ParamsBookingPage />}/>
        <Route path={routeParamsClosures} element={<ParamsClosuresPage />}/>
        <Route path={routeParamsAgendas} element={<ParamsAgendasPage />} />
        <Route path={routeParamsNotifications} element={<ParamsNotificationsPage />} />
        <Route path={routeParamsPersonalization} element={<ParamsPersonalizationPage />}/>
        <Route path={routeParamsPayment} element={<ParamsPaymentPage />} />

        <Route path={routeServiceIndex} element={<ServicesIndexPage />} />
        <Route path={routeSubscriptionIndex} element={<SubscriptionIndexPage />} />

        <Route path={routeParamsDiscounts} element={<ParamsDiscountsListPage />} />
        <Route path={routeParamsDiscountsCreate} element={<ParamsDiscountEditPage />} />
        <Route path={routeParamsDiscountsEdit} element={<ParamsDiscountEditPage />} />

        <Route path={routeParamsGiftcardsDiscounts} element={<ParamsDiscountsListPage />} />
        <Route path={routeParamsGiftcardsDiscountsCreate} element={<ParamsDiscountEditPage />} />
        <Route path={routeParamsGiftcardsDiscountsEdit} element={<ParamsDiscountEditPage />} />

        <Route path={routeEvents} element={<EventsPage />} />

        <Route path={routeDateAgendas} element={<DateAgendasPage />} />
        <Route path={routeAgendas} element={<DateAgendasPage />} />

    </Routes>
}