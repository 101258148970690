import {FC} from "react";
import {routeParamsSubscriptionCreate, routeParamsSubscriptionEdit, routeParamsSubscriptions} from "../../../routes";
import {useAuthStore} from "../../../providers/sso/auth-provider";
import {useConfigurationStore} from "../../../params/configuration-store";
import {ServicesStore} from "../../../services/services-store";
import {Route, Routes} from "react-router";
import {ParamsSubscriptionsListPage} from "./params-subscriptions-list-page";
import {ParamsSubscriptionEditPage} from "./params-subscriptions-edit-page";

const getLocalPath = (fullPath: string): string => {
    const re = new RegExp(`^${routeParamsSubscriptions}`);
    return fullPath.replace(re, '');
}

export const SubscriptionIndexPage: FC = () => {
    const {token, codeBouton} = useAuthStore();
    const {config: {appointment: {paymentActive}}} = useConfigurationStore();

    return <ServicesStore codeBouton={codeBouton} token={token} paymentActive={paymentActive}>
        <Routes>
            <Route path={getLocalPath(routeParamsSubscriptions)} element={<ParamsSubscriptionsListPage/>}/>
            <Route path={getLocalPath(routeParamsSubscriptionCreate)} element={<ParamsSubscriptionEditPage/>}/>
            <Route path={getLocalPath(routeParamsSubscriptionEdit)} element={<ParamsSubscriptionEditPage/>}/>
        </Routes>
    </ServicesStore>
}