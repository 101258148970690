import {File} from "../commons/common-types";

export interface Configuration {
    appointment: AppointmentConfig;
    giftcard: GiftcardConfig;
    paymentPercent: number;
    logoFile?: File;
    coverImageFile?: File;
    currency: string;
    name: string;
    email?: string|null;
    phone?: string|null
}

interface AppointmentConfig {
    paymentActive: boolean;
}

interface GiftcardConfig {
    active: boolean
}

export const live = 'live';
export const test = 'test';
export type Mode = typeof live | typeof test;

export interface PaymentConfig {
    pk_test?: string;
    sk_test?: string;
    pk_live?: string;
    sk_live?: string;
    mode: Mode
}