import axios, { AxiosRequestHeaders } from "axios";
import {ResultPagination, Selection} from "../commons/common-types";
import { Customer } from "./customers-types";

export class CustomersApi {
    public constructor(private apiBaseUri: string) {
    }

    async getCustomersList(codeBouton: string, token: string, limit: number, offset: number, search: string): Promise<ResultPagination<Customer>> {
        const headers: AxiosRequestHeaders = {Authorization: `Bearer ${token}`};
        const response = await axios.get(`${this.apiBaseUri}/${codeBouton}/customers?limit=${limit}&offset=${offset}&search=${search}`, {headers});
        return response.data
    }

    async exportCustomers(codeBouton: string, token: string, body: Selection, search: string): Promise<any> {
        const headers: AxiosRequestHeaders = {Authorization: `Bearer ${token}`, 'Content-Type': 'application/json', 'Accept': 'text/html'};
        const response = await axios.post(`${this.apiBaseUri}/${codeBouton}/export/csv?search=${search}`, body, {headers, responseType: 'text'})
        return response.data
    }

    async getCustomer(codeBouton: string, token: string, id: string): Promise<Customer> {
        const headers: AxiosRequestHeaders = {Authorization: `Bearer ${token}`};
        const response = await axios.get(`${this.apiBaseUri}/${codeBouton}/customers/${id}`, {headers})
        return response.data
    }
}