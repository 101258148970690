import {Dispatch, FC, SetStateAction} from "react";
import {ServiceItem, SubscriptionFormProps} from "./subscription-edit-form";
import {Checkbox, Col, Row, SpanCaption1, SpanSubtitle1} from "@linkeo.com/ui-lib-react";
import {FormattedMessage} from "react-intl";
import styled from "styled-components";

const CustomCol = styled(Col)`
  > div {
    margin: 15px 0 0
  }
`

interface SubscriptionServicesFormProps {
    servicesList: ServiceItem[];
    form: SubscriptionFormProps;
    setForm: Dispatch<SetStateAction<SubscriptionFormProps>>
}

export const SubscriptionServicesForm: FC<SubscriptionServicesFormProps> = ({
                                                                                servicesList,
                                                                                form,
                                                                                setForm
                                                                            }) => {
    const onCheckboxChange = (id: string) => {
        const find = form.services.find(el => el.id === id);
        if (!find) {
            setForm({...form, services: [...form.services, {id}]})
        } else {
            setForm({...form, services: form.services.filter(el => el.id !== id)})
        }
    }

    const areAllServicesSelected = (): boolean => {
        const selectedServices = form.services.map(service => service.id);
        return servicesList.every((service) => selectedServices.includes(service.id))
    }

    const onAllServicesCheckboxClick = () => {
        if (areAllServicesSelected()) {
            setForm({...form, services: []})
        } else {
            setForm({...form, services: servicesList.map(service => ({id: service.id}))})
        }
    }

    return <>
        <div style={{margin: '50px 0 0'}}>
            <SpanSubtitle1>
                <FormattedMessage
                    id={'ParamsSubscriptionServicesFormTitle'}
                    defaultMessage={'Cet abonnement donne accès à un choix limité de prestations'}/>
            </SpanSubtitle1>
        </div>
        <SpanCaption1>
            <FormattedMessage
                id={'ParamsSubscriptionServicesFormSubTitle'}
                defaultMessage={'En cochant cette case, vous pouvez choisir les prestations auxquels auront accès vos clients en souscrivant à cet abonnement.'}/>
        </SpanCaption1>

        <Row style={{marginTop: '25px', marginBottom: '25px'}} gapColumn={15}>
            <Col columns={[12]}>
                <Checkbox value={areAllServicesSelected()} onChange={onAllServicesCheckboxClick}>
                    <FormattedMessage id={'ParamsSubscriptionServicesFormSelectAllLabel'}
                                      defaultMessage={'Tout selectionner'}/>
                </Checkbox>
            </Col>
            {servicesList.map(service =>
                <CustomCol key={service.id}>
                    <Checkbox value={form.services.map(el => el.id).includes(service.id)}
                              onChange={() => onCheckboxChange(service.id)}>
                        {service.title}
                    </Checkbox>
                </CustomCol>)}

        </Row>
    </>
}