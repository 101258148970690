import {FC, useEffect, useState} from "react";
import {Container, Flex, InfiniteScroll, SpanCaption1, Svg, ThemeType, useToaster} from "@linkeo.com/ui-lib-react";
import {FormattedMessage, useIntl} from "react-intl";
import {PageWrapper, PageWrapperSubHeader} from "../../commons/components/page-wrapper";
import {ContainerHeader} from "../../commons/components/container-header";
import {EmptyList} from "../../commons/components/empty-list";
import {routeCustomerCreate} from "../../routes";
import {BackToTop} from "../../commons/components/back-to-top";
import {ROOT_PATH} from "../../config/config";
import {useCustomersStore} from "../../customers/customers-store";
import {AxiosError} from "axios";
import {CustomerCard} from "../../customers/components/customer-card";
import {CustomersNav} from "../../customers/components/customers-nav";
import {usePaymentStore} from "../../params/payment-store";
import {Subscriber, Subscription} from "../../params/payment-types";
import {Customer} from "../../customers/customers-types";
import {useTheme} from "styled-components";
import {Selection} from "../../commons/common-types";

export const CustomersListPage: FC = () => {
    const intl = useIntl();
    const theme = useTheme() as ThemeType;
    const toast = useToaster();
    const {customersList, fetchCustomersList, loadMoreCustomers, exportCustomers} = useCustomersStore();
    const {
        fetchSubscriptionList,
        subscriptionsList,
        fetchSubscribersList,
        loadMoreSubscribers,
        subscribersList,
        exportSubscribers
    } = usePaymentStore();
    const [loading, setLoading] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [lockCustomersList, setLockCustomersList] = useState<boolean>(false);
    const [lockSubscribersList, setLockSubscribersList] = useState<boolean>(false);
    const [list, setList] = useState<Customer[] | Subscriber[]>();
    const [selectedSubscription, setSelectedSubscription] = useState<string>();
    const [searchValue, setSearchValue] = useState<string>('');
    const [getSelection, setSelection] = useState<keyof Selection>("include");
    const [selectionList, setSelectionList] = useState<string[]>([]);
    const [loader, setLoader] = useState<boolean>(false);
    const [exportLoading, setExportLoading] = useState<boolean>(false);
    const BASE_PATH = ROOT_PATH.length > 1 ? ROOT_PATH : '';

    useEffect(() => {
        setLoading(true);
        Promise.all([fetchCustomersList(''), fetchSubscriptionList()])
            .catch((err: AxiosError) => console.error(err))
            .finally(() => setLoading(false))
    }, [fetchCustomersList, fetchSubscriptionList]);

    useEffect(() => {
        setList(customersList)
    }, [customersList])

    useEffect(() => {
        setList(subscribersList)
    }, [subscribersList])

    const onLoadMoreClick = async (search: string) => {
        setIsLoading(true);
        try {
            if (selectedSubscription) {
                const isTotal = await loadMoreSubscribers(selectedSubscription, search);
                setLockSubscribersList(isTotal)
            } else {
                const isTotal = await loadMoreCustomers(search);
                setLockCustomersList(isTotal)
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false)
        }
    }

    const onSelectList = async (subscription?: Subscription) => {
        setLoader(true)
        try {
            if (subscription) {
                setSelectedSubscription(subscription.id);
                setLockSubscribersList(false);
                await fetchSubscribersList(subscription.id, searchValue)
            } else {
                setSelectedSubscription(undefined);
                setLockCustomersList(false);
                await fetchCustomersList(searchValue);
            }
        } catch (e) {
            console.error();
        } finally {
            setLoader(false)
            setSelection("include");
            setSelectionList([]);
        }
    }

    const onCustomerSelect = (id: string) => {
        const find = selectionList.find(el => el === id);
        if (find) {
            setSelectionList(selectionList.filter(el => el !== id))
        } else {
            setSelectionList([...selectionList, id])
        }
    }

    const onSearch = async (search: string) => {
        setLoader(true);
        try {
            if (selectedSubscription) {
                setLockSubscribersList(false);
                await fetchSubscribersList(selectedSubscription, search)
            } else {
                setLockCustomersList(false)
                await fetchCustomersList(search)
            }
        } catch (e) {
            console.error(e)
        } finally {
            setLoader(false)
        }
    }

    const onContactsExport = async () => {
        setExportLoading(true);
        try {
            if (selectedSubscription) {
                await exportSubscribers(getSelection === "exclude" ? {exclude: selectionList} : {include: selectionList}, searchValue)
            } else {
                await exportCustomers(getSelection === "exclude" ? {exclude: selectionList} : {include: selectionList}, searchValue)
            }
        } catch (e) {
            toast(intl.formatMessage({
                id: 'customersExportMessageError',
                defaultMessage: 'Une erreur est survenue lors de l\'export'
            }))
        } finally {
            setExportLoading(false)
        }
    }

    return <PageWrapper isLoading={loading}>
        <PageWrapperSubHeader>
            <CustomersNav
                onSelectClick={(selection) => {
                    setSelection(selection);
                    setSelectionList([])
                }}
                getSelection={getSelection}
                subscriptionsList={subscriptionsList}
                onSelectSubscriptionList={onSelectList}
                onSearch={onSearch}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                exportLoading={exportLoading}
                onExportClick={onContactsExport}
            />
        </PageWrapperSubHeader>
        <Container size={'lg'}>
            {list ? <>
                    <ContainerHeader
                        title={intl.formatMessage({
                            id: 'CustomerListPageContainerHeaderTitle',
                            defaultMessage: 'Fichier clients'
                        })}
                        subtitle={intl.formatMessage({
                            id: 'CustomerListPageContainerHeaderSubtitle',
                            defaultMessage: 'Retrouvez ici la liste des clients ayant créé un compte sur votre site.'
                        })}
                        buttonLabel={intl.formatMessage({
                            id: 'CustomerListPageContainerButtonLabel',
                            defaultMessage: '+ Ajouter un client'
                        })}
                        onClick={() => window.location.pathname = BASE_PATH + `client/addclient`}
                    />
                    {loader
                        ? <Flex justifyContent={'center'}>
                            <Svg animatedIcon={"spinner"} stroke={theme.colors.grey30}/>
                        </Flex>
                        : list.length > 0 ?
                            <InfiniteScroll isLoading={isLoading}
                                            lock={selectedSubscription ? lockSubscribersList : lockCustomersList}
                                            onNext={() => onLoadMoreClick(searchValue)}
                                            moreResultLabel={intl.formatMessage({
                                                id: 'CustomerListPageMoreResultLabel',
                                                defaultMessage: '+ Charger plus de contacts'
                                            })}>
                                {list.map(customer => {
                                    return <CustomerCard key={customer.id}
                                                         firstName={customer.firstName}
                                                         lastName={customer.lastName}
                                                         email={customer.email}
                                                         phone={customer.phone}
                                                         isSelected={getSelection === "exclude"}
                                                         onSelect={() => onCustomerSelect(customer.id)}
                                                         onEditClick={() => window.location.pathname = BASE_PATH + `client/addclient/${"customerId" in customer ? customer.customerId : customer.id}`}
                                    />
                                })}
                            </InfiniteScroll>
                            : <SpanCaption1 style={{display: 'flex', justifyContent: 'center'}}>
                                {selectedSubscription
                                    ? <FormattedMessage id={'customersListPageNoSubscribersCaption'}
                                                        defaultMessage={'Aucun abonné'}/>
                                    : <FormattedMessage id={'customersListPageNoCustomersCaption'}
                                                        defaultMessage={'Pas de contact'}/>}
                            </SpanCaption1>}
                </>
                : <EmptyList
                    buttonLabel={intl.formatMessage({
                        id: 'CustomerListPageContainerButtonLabel',
                        defaultMessage: '+ Ajouter un client'
                    })}
                    to={routeCustomerCreate}
                    title={intl.formatMessage({
                        id: 'CustomerListPageContainerHeaderTitle',
                        defaultMessage: 'Fichier clients'
                    })}
                    subtitle={intl.formatMessage({
                        id: 'CustomersListPageEmptyListSubtitle',
                        defaultMessage: 'Vous n\'avez pas encore de contacts dans votre fichier clients'
                    })}
                    icon={'person'}/>
            }
            <BackToTop/>
        </Container>
    </PageWrapper>
}