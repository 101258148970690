import { FC, ReactNode } from "react";
import styled from "styled-components";

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

const TooltipText = styled.div`
  visibility: hidden;
  font-family: 'Raleway', 'sans-serif';
  font-size: 12px;
  text-align: center;
  width: 130px;
  background-color: ${({theme}) => theme.colors.grey10};
  color: ${({theme}) => theme.colors.light};
  text-align: center;
  border-radius: 4px;
  padding: 6px;
  position: absolute;
  bottom: 125%; 
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${({theme}) => theme.colors.grey10} transparent transparent transparent;
  }
`;

const TooltipWrapper = styled(TooltipContainer)`
  &:hover ${TooltipText} {
    visibility: visible;
    opacity: 1;
  }
`;

interface ToolTipProps {
    children: ReactNode;
    message: string;
}

export const Tooltip: FC<ToolTipProps> = ({ children, message }) => {
    return (
        <TooltipWrapper>
            {children}
            <TooltipText>
                {message}
            </TooltipText>
        </TooltipWrapper>
    );
};
