export const servicesApi: string = process.env.REACT_APP_SERVICES_API || "http://localhost:8083"
export const appointmentsApi: string = process.env.REACT_APP_APPOINTMENTS_API || "http://localhost:8082"
export const configurationApi: string = process.env.REACT_APP_CONFIGURATION_API || "http://localhost:8081"
export const uploaderApi: string = process.env.REACT_APP_UPLOADER_API || "http://localhost:8085"
export const paymentApi: string = process.env.REACT_APP_PAYMENT_API || "http://localhost:8084"
export const ssoUri: string = process.env.REACT_APP_SSO_URI || "https://sso.devel.idep.us"
export const ssoClientId: string = process.env.REACT_APP_SSO_CLIENT_ID || "test-planner-back"
export const ssoClientSecret: string = process.env.REACT_APP_SSO_CLIENT_SECRET || "a52768a59571e623923160d6de0a4af3ede6bdce"
export const ROOT_PATH:string = process.env.REACT_APP_ROOT_PATH || '/'
export const FRONT_URI: string = process.env.REACT_APP_FRONT_URI || "http://localhost:3437";
export const customersApi: string = process.env.REACT_APP_CUSTOMERS_API || "http://localhost:8080"