export const routeHome = '/';
export const routeOptions = "/params/options/";
export const routeOptionsCreate = "/params/options/edit";
export const routeOptionsEdit = "/params/options/edit/:optionId";
export const routeCustomersIndex = '/customers/*'
export const routeCustomers = "/customers";
export const routeCustomerCreate = "/customers/edit"
export const routeCustomersEdit = "/customers/edit/:id/subscriptions";
export const routeGiftcards = "/giftcards";
export const routeGiftcardCreate = "/giftcards/create"
export const routeParams = "/params"
export const routeParamsBooking = "/params/booking";
export const routeParamsClosures = '/params/closures';
export const routeParamsAgendas = '/params/agendas';
export const routeServiceIndex = '/params/services/*';
export const routeParamsServices = '/params/services';
export const routeParamsServiceCreate = '/params/services/edit';
export const routeParamsServiceEdit = '/params/services/edit/:id';
export const routeParamsNotifications = '/params/notifications';
export const routeParamsDiscounts = '/params/discounts';
export const routeParamsDiscountsCreate = '/params/discounts/edit';
export const routeParamsDiscountsEdit = '/params/discounts/edit/:id';
export const routeParamsGiftcardsDiscounts = '/params/giftcards/discounts'
export const routeParamsGiftcardsDiscountsCreate = '/params/giftcards/discounts/edit';
export const routeParamsGiftcardsDiscountsEdit = '/params/giftcards/discounts/edit/:id';
export const routeParamsPersonalization = '/params/personalization';
export const routeParamsPayment = '/params/payment';
export const routeEvents = '/events';
export const routeDateAgendas = '/agendas/:editDate';
export const routeAgendas = '/agendas';
export const routeGuide = '/guide';
export const routeParamsSubscriptions = '/params/subscriptions';
export const routeSubscriptionIndex = '/params/subscriptions/*';
export const routeParamsSubscriptionCreate = '/params/subscriptions/edit'
export const routeParamsSubscriptionEdit = '/params/subscriptions/edit/:id'
