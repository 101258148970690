import { FC } from "react";
import { Route, Routes } from "react-router";
import { routeCustomers, routeCustomersEdit, routeCustomerCreate } from "../../routes";
import { CustomerEditPage } from "./customer-edit-page";
import { CustomersListPage } from "./customers-list-page";
import { CustomersStore } from "../../customers/customers-store";
import { useAuthStore } from "../../providers/sso/auth-provider";

const getLocalPath = (fullPath: string): string => {
    const re = new RegExp(`^${routeCustomers}`);
    return fullPath.replace(re, '');
}

export const CustomersIndexPage: FC = () => {
    const {token, codeBouton} = useAuthStore();

    return <CustomersStore token={token} codeBouton={codeBouton}>
        <Routes>
            <Route path={getLocalPath(routeCustomers)} element={<CustomersListPage />} />
            <Route path={getLocalPath(routeCustomersEdit)} element={<CustomerEditPage />} />
            <Route path={getLocalPath(routeCustomerCreate)} element={<CustomerEditPage />} />
        </Routes>
    </CustomersStore>
}