import React, {FC} from "react";
import {Button, SpanButton} from "@linkeo.com/ui-lib-react";
import {useIntl} from "react-intl";

interface DialogFooterProps {
    onCancelClick: () => void;
    onApproveClick: () => void;
    loading?: boolean
}

export const DialogFooter: FC<DialogFooterProps> = ({onCancelClick, onApproveClick, loading}) => {
    const intl = useIntl();

    return <>
        <Button colorType={'secondary'}
                onClick={onCancelClick}>
            <SpanButton>{intl.formatMessage({id: 'DialogConfirmButtonNo', defaultMessage: 'Non'})}</SpanButton>
        </Button>
        <Button colorType={'primary'} loading={loading}
                onClick={onApproveClick}>
            <SpanButton>{intl.formatMessage({id: 'DialogConfirmButtonYes', defaultMessage: 'Oui'})}</SpanButton>
        </Button>
    </>
}