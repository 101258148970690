import {Dispatch, FC, SetStateAction} from "react";
import {Col, Input, Row, SpanBody2, Textarea} from "@linkeo.com/ui-lib-react";
import {FormattedMessage, useIntl} from "react-intl";
import {SubscriptionFormProps} from "./subscription-edit-form";
import {CurrencyInputNumber} from "./currency-input-number";
import {updateItemInArray} from "../../../utils/misc";

interface SubscriptionInfoFormProps {
    form: SubscriptionFormProps;
    setForm: Dispatch<SetStateAction<SubscriptionFormProps>>;
    readOnly: boolean
}

export const SubscriptionInfoForm: FC<SubscriptionInfoFormProps> = ({form, setForm, readOnly}) => {
    const intl = useIntl();

    return <Row>
        <Col columns={[12, 12, 6]}>
            <Input
                label={intl.formatMessage({
                    id: 'ParamsSubscriptionInfoFormNameInputLabel',
                    defaultMessage: 'Nom de l’abonnement'
                })} required
                disabled={readOnly}
                value={form.name}
                onChange={(name) => setForm({...form, name})}/>
            <CurrencyInputNumber
                inputValue={form.subscriptionFees}
                readOnly={readOnly}
                onChange={(subscriptionFees) => setForm({...form, subscriptionFees})}
                currency={form.currency}
                label={intl.formatMessage({
                    id: 'ParamsSubscriptionInfoFromSubFeesInputLabel',
                    defaultMessage: 'Frais d’inscription'
                })}
            />
            <SpanBody2>
                <FormattedMessage id={'ParamsSubscriptionInfoFormPlansTitle'} defaultMessage={'Plans Tarifaires'}/>
            </SpanBody2>
            {form.plans.map((plan, index) =>
                <CurrencyInputNumber
                    key={index}
                    active={plan.active}
                    readOnly={readOnly}
                    onActiveChange={(active) => setForm({
                        ...form,
                        plans: updateItemInArray(form.plans, index, {...plan, active})
                    })}
                    label={intl.formatMessage({id: `ParamsSubscriptionPlanLabel${plan.numberOfMonths}`})}
                    inputValue={plan.price}
                    onChange={(price) => setForm({
                        ...form,
                        plans: updateItemInArray(form.plans, index, {...plan, price})
                    })}
                    currency={form.currency}
                />)}
        </Col>
        <Col columns={[12, 12, 6]}>
            <Textarea value={form.subscriptionConditions}
                      onChange={(subscriptionConditions) => setForm({...form, subscriptionConditions})}
                      style={{maxHeight: '120px', minHeight: '120px', overflow: 'auto'}}
                      disabled={readOnly}
                      label={intl.formatMessage({
                          id: 'ParamsSubscriptionEditFormConditionsLabel',
                          defaultMessage: 'Conditions d’inscription'
                      })}/>
            <Textarea value={form.unsubscriptionConditions}
                      onChange={(unsubscriptionConditions) => setForm({...form, unsubscriptionConditions})}
                      style={{maxHeight: '120px', minHeight: '120px', overflow: 'auto'}}
                      disabled={readOnly}
                      label={intl.formatMessage({
                          id: 'ParamsSubscriptionEditFormConditionsDesinscriptionLabel',
                          defaultMessage: 'Conditions de désincription'
                      })}/>
            <Textarea value={form.otherSubscriptionConditions}
                      onChange={(otherSubscriptionConditions) => setForm({...form, otherSubscriptionConditions})}
                      style={{maxHeight: '120px', minHeight: '120px', overflow: 'auto'}}
                      disabled={readOnly}
                      label={intl.formatMessage({
                          id: 'ParamsSubscriptionEditFormConditionsOtherLabel',
                          defaultMessage: 'Autres conditions d’abonnement'
                      })}/>
        </Col>
    </Row>
}